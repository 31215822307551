<template>
  <Authenticated>
    <div class="actions">
      <h2>Settings for {{ site }}</h2>
    </div>

    <div class="payment_options" v-if="site">
      <form @submit.prevent>
        <h4>Display Theme</h4>
        <Theme />

        <h4>
          Website Controls for {{ site }}
          <button
            class="save-btn"
            @click="
              updateSettings([siteSettings, localSettings]);
              saveSiteSettings();
              openCloseSite();
            ">
            {{ save_btn_text }}
          </button>
        </h4>
        <ul class="grid wrapper">
          <li class="modifierBox">
            <p>Close Website</p>
            <Toggle class="toggle" onLabel="Closed" offLabel="Open" v-model="siteSettings.openClose" :value="siteSettings.openClose" />
          </li>
          <li class="modifierBox">
            <p>Region</p>
            <p v-if="getCurrentRegion">{{ getCurrentRegion[0].name }}</p>
            <select name="region" id="opening-hours" class="dropdown" v-model="selectedRegion">
              <option value="" disabled>Change region</option>
              <option v-for="region in getRegionNames" :key="region" :value="region">{{ region }}</option>
            </select>
          </li>
          <li>
            <button @click="openOperatingHours = !openOperatingHours" class="hours-btn"><font-awesome-icon icon="fa-plus" /> <br />Add Operating Hours</button>
          </li>

          <OperatingHoursModal
            @saveHours="saveOpeningHours"
            @showOperatingHoursModal="openOperatingHours = !openOperatingHours"
            @updateYear="setYear"
            :week="weekFinal"
            :holidays="holidayFinal"
            :thisYear="thisYear"
            :nextYear="nextYear"
            :currentYear="currentYear"
            :open="openOperatingHours" />
        </ul>

        <h4>
          Checkout Options (Site Wide)
          <button
            class="save-btn"
            @click="
              updateSettings([siteSettings, localSettings]);
              saveSiteSettings();
            ">
            {{ save_btn_text }}
          </button>
        </h4>
        <ul class="grid wrapper">
          <li class="modifierBox">
            <p>Require order name?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.nameRequired" :value="siteSettings.nameRequired" />
          </li>
          <li class="modifierBox">
            <p>Allow order comments?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.notesRequired" :value="siteSettings.notesRequired" />
          </li>
          <li class="modifierBox">
            <p>Allow Images?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.imagesRequired" :value="siteSettings.imagesRequired" />
          </li>
          <li class="modifierBox">
            <p>Future Orders?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.futurePickup" :value="siteSettings.futurePickup" />
          </li>
        </ul>

        <h4>
          Payment Options (Site Wide)
          <button
            class="save-btn"
            @click="
              updateSettings([siteSettings, localSettings]);
              saveSiteSettings();
            ">
            {{ save_btn_text }}
          </button>
        </h4>
        <ul class="grid wrapper">
          <li class="modifierBox">
            <p>Allow EFTPOS?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.eftpos_pay" :value="siteSettings.eftpos_pay" />
          </li>
          <li class="modifierBox">
            <p>Allow Post Pay?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.post_pay" :value="siteSettings.post_pay" />
          </li>
          <li class="modifierBox">
            <p>Allow Cash?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.cash_pay" :value="siteSettings.cash_pay" />
          </li>
          <li class="modifierBox">
            <p>Allow Refunds?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.refund_allowed" :value="siteSettings.refund_allowed" />
          </li>
          <li class="modifierBox">
            <p>Surcharge?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.surcharge" :value="siteSettings.surcharge" />
          </li>
          <li class="modifierBox">
            <p>Accounts?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.accounts" :value="siteSettings.accounts" />
          </li>
          <li class="modifierBox">
            <p>Vouchers?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.vouchers" :value="siteSettings.vouchers" />
          </li>
        </ul>
        <Discounts :channel="channel" />

        <h4>Delivery Options:</h4>
        <ul class="grid wrapper">
          <li class="modifierBox" v-for="(option, idx) in siteSettings.delivery_options" :key="idx">
            <h3 class="del-option">{{ option }}</h3>
            <span @click.stop.prevent="removeDeliveryOption(idx)"><font-awesome-icon icon="fa-xmark" /></span>
          </li>
          <li class="modifierBox">
            <input type="text" name="delivery" v-model="newDelivery" placeholder="Add option" /><span @click.stop.prevent="addDeliveryOption"><font-awesome-icon icon="fa-plus" /></span>
          </li>
        </ul>

        <h4>
          Station Settings:
          <button @click="saveEFTPOSSettings" class="save-btn">{{ save_pos_btn_text }}</button>
        </h4>
        <ul class="grid wrapper eftpos">
          <li class="modifierBox">
            <div class="siteSettings">
              <p>EFTPOS settings(NZ only):</p>
              <div v-if="eftposSettings.length">
                <label for="eftpos_ip">IP</label>
                <input type="text" name="eftpos" id="eftpos_ip" v-model="eftposSettings[0].ip" />
                <label for="eftpos_port">Port</label>
                <input type="text" name="eftpos" id="eftpos_port" v-model="eftposSettings[0].port" />
              </div>
            </div>
          </li>
        </ul>
      </form>
    </div>
  </Authenticated>
</template>

<script>
import Discounts from "@/components/Discounts/Discounts.vue";
import Authenticated from "@/components/_layouts/Authenticated";
import { mapActions, mapGetters } from "vuex";
import createdMixin from "@/components/_mixins/createdMixin";
import Toggle from "@vueform/toggle";
import Theme from "./Theme.vue";
import OperatingHoursModal from "./OperatingHoursModal.vue";
import holidays2024 from "../../../public/holidays.json";
import holidays2025 from "../../../public/holidays2025.json";
// import { splice } from "core-js/core/array";
//import dayjs from "dayjs";

export default {
  name: "Settings",
  mixins: [createdMixin],
  components: {
    Authenticated,
    Discounts,
    Toggle,
    Theme,
    OperatingHoursModal,
  },
  data() {
    return {
      channel: null,
      station: window.localStorage.getItem("station"),
      site: window.localStorage.getItem("site"),
      eftposIP: null,
      eftposPort: null,
      save_btn_text: "Save",
      save_pos_btn_text: "Save",
      darkMode: false,
      lightMode: false,
      osDefault: true,
      selectedRegion: "",
      openClose: false,
      openOperatingHours: false,
      thisYear: new Date(Date.now()).getFullYear(),
      nextYear: new Date(Date.now()).getFullYear() + 1,
      currentYear: new Date(Date.now()).getFullYear(),
      holidays: [],
      holidayFinal: [],
      weekFinal: [],
      daysOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      newDelivery: "",
    };
  },
  computed: {
    ...mapGetters({
      getClosed: "getClosed",
      site: "site",
      siteSettings: "siteSettings",
      localSettings: "localSettings",
      eftposSettings: "eftposSettings",
    }),

    holidayObj() {
      return this.natHolidays.map((day) => {
        const dayOpen = { open: false, openTime: "", closeTime: "", name: "", date: "", regional: false };
        dayOpen.name = day.HolidayName;
        dayOpen.date = day.ActualDate;
        dayOpen.regional = day.Regional;
        return dayOpen;
      });
    },

    weekObj() {
      return this.daysOfWeek.map((day) => {
        const dayOpen = { open: false, openTime: "", closeTime: "", name: "" };
        dayOpen.name = day;
        return dayOpen;
      });
    },

    natHolidays() {
      const nats = this.holidays.filter((hol) => hol.Type == "National");
      nats.push(new Proxy({ HolidayName: "", ActualDate: "", Regional: true }, {}));
      return nats;
    },

    getRegionNames() {
      return this.getRegions.map((holiday) => {
        let region = holiday.HolidayName.split(" ").splice(0, holiday.HolidayName.split(" ").length - 2);
        return region.join(" ");
      });
    },

    getRegions() {
      return this.holidays.filter((hol) => hol.Type == "Regional");
    },

    getCurrentRegion() {
      return "hours" in this.siteSettings ? this.siteSettings.hours.holiday.filter((day) => day.regional) : false;
    },
  },
  async mounted() {
    this.$store.commit("currPage", "settings");
    this.setYear(this.thisYear);
    this.holidayFinal = "hours" in this.siteSettings ? this.siteSettings.hours.holiday : this.holidayObj;
    this.weekFinal = "hours" in this.siteSettings ? this.siteSettings.hours.week : this.weekObj;
  },

  methods: {
    ...mapActions(["updateSettings"]),

    addDeliveryOption() {
      if (this.newDelivery) {
        this.siteSettings.delivery_options.push(this.newDelivery);
        this.newDelivery = "";
        this.saveSiteSettings();
        console.log(this.siteSettings);
      }
    },

    removeDeliveryOption(idx) {
      this.siteSettings.delivery_options.splice(idx, 1);
      this.saveSiteSettings();
      console.log(this.siteSettings);
    },

    saveEFTPOSSettings() {
      this.save_pos_btn_text = "Saving";
      let stations = window.localStorage.getItem("stations");
      stations = JSON.parse(stations);
      stations.find((station) => station.name == this.localSettings[0].name && station.site == this.localSettings[0].site).eftpos = this.localSettings[0].eftpos;

      this.channel
        .push("company:pos_station:update", stations)
        .receive("ok", () => {
          this.save_pos_btn_text = "Saved";
        }) //console.log("saved", reply)
        .receive("error", () => {
          this.save_pos_btn_text = "Error";
        });
    },
    saveSiteSettings() {
      this.save_btn_text = "Saving";
      this.channel
        .push("company:site:update", this.siteSettings)
        .receive("ok", () => {
          this.save_btn_text = "Saved";
        })
        .receive("error", () => {
          this.save_btn_text = "Error";
        });
    },
    openCloseSite() {
      this.channel.push("company:site:close", { status: this.getClosed });
    },

    setYear(year) {
      // console.log("changed Year to ", year, this.currentYear);
      if (this.thisYear.toString() == year.toString()) {
        this.holidays = holidays2024;
      } else {
        this.holidays = holidays2025;
      }
      this.currentYear = year;
    },

    saveOpeningHours() {
      this.siteSettings.hours = { holiday: this.holidayFinal, week: this.weekFinal };
      this.updateSettings([this.siteSettings, this.localSettings]);
      this.saveSiteSettings();
    },
  },
  watch: {
    selectedRegion() {
      let matchedRegion = this.getRegions.filter((holiday) => holiday.HolidayName.includes(this.selectedRegion));
      this.holidayFinal.forEach((h) => {
        if ("regional" in h && h.regional) {
          h.name = matchedRegion[0].HolidayName;
          h.date = matchedRegion[0].ActualDate;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "public/layout";
@import "public/wrapper";
@import "public/actions";

.eftpos {
  grid-column: 1 / 3;
}

li {
  p {
    margin: 0.5rem;
  }
  .del-option {
    text-transform: capitalize;
  }
}

.hours-btn {
  background-color: var(--secondary-colour);
  color: var(--banner-colour);
  border: none;
  border-radius: 5px;
  padding: 2.5rem;
  margin: 0.5rem;
  cursor: pointer;
  transition: ease-in-out 500ms;
  border: 3px solid var(--banner-colour);
  &:hover {
    background-color: var(--banner-colour);
    color: var(--alt-text-colour);
  }
}

form {
  label {
    display: contents;
  }
}
.toggle {
  --toggle-width: 12rem;
  --toggle-border-off: black;
  --toggle-border-on: black;
  --toggle-bg-on: var(--action-colour);
  --toggle-border: 2px;
  --toggle-height: 48px;
  --toggle-font-size: 1rem;
  outline-color: green;
  outline: 0;
  border-color: var(--action-colour);
  border-left-width: 1px;
  border-right-width: 1px;
}
.dropdown {
  width: 100%;
  border: 1px solid var(--action-colour);
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: var(--secondary-colour);
  color: var(--text-colour);
  font-size: 1rem;
  margin-top: 0.5rem;
}

.timePicker {
  input {
    width: 50%;
    border: 1px solid var(--action-colour);
    border-radius: 0.5rem;
    padding: 0.5rem;
    background-color: var(--secondary-colour);
    color: var(--text-colour);
    font-size: 1rem;
    margin: 0.5rem;
  }
}
.details {
  align-items: flex-start;
  p {
    margin: 3px;
    text-align: left;
  }
}

@media only screen and (orientation: portrait) {
  .toggle {
    --toggle-width: 7rem;
  }
  .hours-btn {
    padding: 1rem;
  }
}
</style>
